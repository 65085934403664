import React from "react"
import { Link } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"
import { FaVideo, FaRegFilePdf, FaMusic } from "react-icons/fa"
import {
  AiOutlineFileZip,
  AiOutlineFileUnknown,
  AiOutlineLink,
} from "react-icons/ai"

import FolderIcon from "../components/FolderIcon"
import S3Image from "../components/S3Image"

var md = require('markdown-it')();

// Remember old renderer, if overridden, or proxy to default renderer
var defaultRender = md.renderer.rules.link_open || function(tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options);
};

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // If you are sure other plugins can't add `target` - drop check below
  var aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

function getFilePathExtension(path) {
  var filename = path
    .split("\\")
    .pop()
    .split("/")
    .pop()
  return filename.substr(
    (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
  )
}

const SortChevron = ({ className = "" }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    focusable="false"
    className={className}
    role="img"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M6 8L3 4h6z" fill="#637282"></path>
    </g>
  </svg>
)

const SortArrow = ({ className = "" }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    focusable="false"
    className={className}
    role="img"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M6 10L3 6h6l-3 4zM5 2h2v4H5V2z" fill="#637282"></path>
    </g>
  </svg>
)

const FileIcon = ({ filename, large }) => {
  const fileType = getFilePathExtension(filename)

  let icon = null

  switch (fileType) {
    case "mp3":
    case "wav":
      icon = <FaMusic className="w-4 h-auto" />
      break

    case "mp4":
    case "mov":
      icon = <FaVideo className="w-4 h-auto" />
      break

    case "jpeg":
    case "jpg":
    case "gif":
    case "png":
      //icon = <FaImage className="w-4 h-auto" />
      icon = (
        <S3Image
          filename={`${filename}`}
          fallback={
            <img
              src={`https://6lackbox-prod.s3.amazonaws.com/${filename}`}
              alt={filename}
              className="absolute inset-0 object-cover w-full h-full"
            />
          }
          className="absolute inset-0 object-cover w-full h-full"
        />
      )
      break

    case "pdf":
      icon = <FaRegFilePdf className="w-4 h-auto" />
      break

    case "zip":
      icon = <AiOutlineFileZip className="w-4 h-auto" />
      break
      case "url":
        icon = <AiOutlineLink className="w-4 h-auto" />
        break

    default:
      icon = <AiOutlineFileUnknown className="w-4 h-auto" />
  }

  return (
    <div
      className={`relative flex items-center justify-center text-black bg-gray-200 rounded ${
        large ? "w-12 h-12 m-3" : "w-10 h-10"
      }`}
    >
      {icon}
    </div>
  )
}

const FileBrowser = ({ files = [] }) => {
  const listOfFiles = files.map(file => {
    let name = ""
    let isFolder = false
    if (file.Key.replace(/^.*[\\\/]/, "")) {
      name = file.Key.replace(/^.*[\\\/]/, "")
    } else {
      isFolder = true
      name = file.Key.replace(/\/$/, "")
        .split("/")
        .slice(-1)
        .pop()
    }
    return {
      name: name,
      modified: "--",
      members: "Only you",
      isFolder,
      path: file.Key,
    }
  })

  const [previewUri, setPreviewUri] = useQueryParam("preview", StringParam)

  const renderFileLink = file => {
    const fileType = getFilePathExtension(file.name)
    if (file.isFolder) {
      return (
        <Link
          to={`/${file.path}`}
          className="text-sm font-medium leading-5 text-theme-white hover:text-theme-highlight"
        >
          {file.name}
        </Link>
      )
    } else if (fileType === "url") {
      let linkParsed = file.name
        .split(".")
        .slice(0, -1)
        .join(".")
      linkParsed = decodeURIComponent(linkParsed)
      return (
        <div
          className="text-sm font-medium leading-5 text-theme-white hover:text-theme-highlight"
          dangerouslySetInnerHTML={{__html: md.renderInline( linkParsed)}}
        >
        </div>
      )
    } else {
      return (
        <button
          href={`${process.env.GATSBY_S3_URL}${file.path}`}
          onClick={() => setPreviewUri(file.path, "pushIn")}
          className="text-sm font-medium leading-5 text-theme-white hover:text-theme-highlight"
        >
          {file.name}
        </button>
      )
    }
  }

  return (
    <div>
      <div className="flex flex-wrap sm:hidden">
        {listOfFiles.map((file, idx) => (
          <div className="w-1/3 mb-3" key={idx}>
            <div className="relative flex flex-col items-center">
              <div className="flex-shrink-0">
                {file.isFolder ? (
                  <FolderIcon className="w-20 h-20 -m-1" />
                ) : (
                  <FileIcon filename={file.path} large />
                )}
              </div>
              <div className="w-4/5 text-center truncate">
                <span className="text-sm font-medium leading-5 text-theme-white">
                  {file.name}
                </span>
              </div>
              {file.isFolder ? (
                <Link to={`/${file.path}`} className="absolute inset-0"></Link>
              ) : (
                <button
                  onClick={() => setPreviewUri(file.path, "pushIn")}
                  className="absolute inset-0 w-full h-full"
                ></button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex-col hidden sm:flex">
        <div className="py-2 -my-2 overflow-x-auto">
          <div
            className="inline-block w-full min-w-full overflow-hidden align-middle"
            style={{ minWidth: 600 }}
          >
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="py-2 pr-6 text-xs font-medium text-left border-b border-theme-mid bg-gray-50 text-theme-white">
                    <span>Name</span>
                    <SortArrow className="inline-block ml-1" />
                  </th>
                  <th className="hidden px-6 py-2 text-xs font-medium text-left border-b xl:table-cell border-theme-mid bg-gray-50 text-theme-white">
                    <span>Modified</span>
                    <SortChevron className="inline-block ml-1" />
                  </th>
                  <th className="px-6 py-2 text-xs font-medium text-left border-b border-theme-mid bg-gray-50 text-theme-white">
                    <span>Members</span>
                    <SortChevron className="inline-block ml-1" />
                  </th>
                  <th className="flex items-center justify-end py-2 pl-6 text-xs font-medium text-right border-b border-theme-mid bg-gray-50 text-theme-white">
                    <button className="inline-block ">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        focusable="false"
                        className="mt-1"
                        role="img"
                      >
                        <g fill="#637282" fillRule="evenodd">
                          <path d="M6 15h2v2H6zM10 15h8v2h-8zM6 11h2v2H6zM10 11h8v2h-8zM6 7h2v2H6zM10 7h8v2h-8z"></path>
                        </g>
                      </svg>
                    </button>
                    <SortChevron className="inline-block mt-1 ml-1" />
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {listOfFiles.map((file, idx) => (
                  <tr className="group" key={idx}>
                    <td className="px-3 py-3 whitespace-no-wrap border-b border-theme-mid">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                          {file.isFolder ? (
                            <FolderIcon className="w-12 h-12 -m-1 opacity-50 group-hover:opacity-100" />
                          ) : (
                            <FileIcon filename={file.path} />
                          )}
                        </div>
                        <div className="ml-4">{renderFileLink(file)}</div>
                      </div>
                    </td>
                    <td className="hidden px-6 py-4 text-sm leading-5 whitespace-no-wrap border-b xl:table-cell border-theme-mid text-theme-white">
                      {file.modified}
                    </td>
                    <td className="px-6 py-4 text-sm leading-5 whitespace-no-wrap border-b border-theme-mid text-theme-white">
                      {file.members}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-theme-mid">
                      <a
                        href="#"
                        className="flex items-center justify-center w-8 px-1 py-1 border border-transparent rounded text-theme-light hover:border-theme-highlight hover:text-theme-highlight focus:outline-none focus:underline"
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          className="block w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                        </svg>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileBrowser
